.appContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  margin: 0em 1em 0em 1em;
  color: black;
}

.link:first-child {
  margin-left: 0.4em;
}

.link:hover {
  text-decoration: underline;
}

a {
  text-decoration: none;
}
