.total {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12% 0% 0% 0%;
  width: 100%;
}

.me {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.picture {
  width: 20vw;
  height: 100%;
  padding-right: 5%;
}

.name {
  text-align: center;
  font-size: 5em;
}

.info {
  display: flex;
  flex-direction: column;
}

.job {
  font-size: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.link {
  margin: 0.5em 5% 0em 5%;
  color: black;
  font-size: 2em;
  justify-self: center;
  text-decoration: underline;
}

@media screen and (max-width: 900px) {
  .me {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .picture {
    width: 20vw;
    height: 100%;
    padding-right: 5%;
  }

  .name {
    text-align: center;

    font-size: 5em;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .job {
    font-size: 1.3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .link {
    margin: 0.5em 5% 0em 5%;
    color: black;
    font-size: 2em;
    justify-self: center;
    text-decoration: underline;
  }
}

@media screen and (max-width: 479px) {
  .me {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .picture {
    width: 60vw;
    height: 100%;
    padding-right: 0%;
  }

  .name {
    font-size: 3em;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .job {
    font-size: 1.3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .link {
    margin: 0.5em 5% 0em 5%;
    color: black;
    font-size: 1.3em;
    justify-self: center;
    text-decoration: underline;
  }
}

.underline {
  text-decoration: underline;
}

.activated {
  text-decoration: underline;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

a {
  text-decoration: none;
}
